import { onLocalStorageChange } from '@dpgradio/creative'
import { useCurrentChannelStore } from './stores/currentChannel'
import { watch } from 'vue'

const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    // Ignore error: either localStorage is disabled or full
    console.error(e)
  }
}

const startPinging = (key) => {
  setInterval(() => {
    setLocalStorage(key, true)
  }, 300)
}

export default function initializeSiteCommunication() {
  const currentChannel = useCurrentChannelStore()

  startPinging('radioplayer-popup')

  setLocalStorage('lastChannelState', null)

  onLocalStorageChange('playing', (playing) =>
    playing === 'stop' ? currentChannel.player.stop() : currentChannel.player.play()
  )

  watch(
    () => currentChannel.player.state,
    (state) => {
      if (state === 'playing') {
        setLocalStorage('playing', 'play')
        setLocalStorage('buffering', 'STREAM_READY')

        setLocalStorage('lastChannel', currentChannel.stationId)
        setLocalStorage('lastChannelState', currentChannel.stationId)
        setLocalStorage('channel', currentChannel.stationId)
      }

      if (state === 'stopped' || state === 'paused') {
        setLocalStorage('playing', 'stop')

        setLocalStorage('lastChannel', null)
        setLocalStorage('lastChannelState', currentChannel.stationId)
        setLocalStorage('channel', null)
      }

      if (state === 'loading') {
        setLocalStorage('buffering', 'STREAM_BUFFERING')

        setLocalStorage('channel', currentChannel.stationId)
      }
    }
  )

  window.addEventListener('beforeunload', () => {
    setLocalStorage('lastChannel', null)
    setLocalStorage('lastChannelState', null)
    setLocalStorage('channel', null)
    setLocalStorage('buffering', 'STREAM_STOPPED')
  })
}
