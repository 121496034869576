<script setup>
import { cdnImageUrl } from '@dpgradio/creative'
import { computed } from 'vue'
import QMusicCoverPlaceholder from '../../assets/images/qmusic-cover-placeholder.jpg'
import WillyCoverPlaceholder from '../../assets/images/willy-cover-placeholder.jpg'
import JoeCoverPlaceholder from '../../assets/images/joe-cover-placeholder.jpg'
import { config } from '@dpgradio/creative'

const props = defineProps({
  track: {
    type: Object,
    default: null,
  },
})

const theme = config('app.theme')

const CoverImages = {
  'qmusic-theme': QMusicCoverPlaceholder,
  'willy-theme': WillyCoverPlaceholder,
  'joe-theme': JoeCoverPlaceholder,
}

const imageOrDefault = computed(() => cdnImageUrl(props.track?.thumbnail, 'w480') || CoverImages[theme])
</script>

<template>
  <img
    :src="imageOrDefault"
    class="inline-block w-full h-full aspect-square rounded-lg shadow-2xl willy:rounded-none"
  />
</template>
