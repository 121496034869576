<script setup>
import { config } from '@dpgradio/creative'
import { useUserStore } from '../stores/user'
import { Dialog, DialogPanel, DialogTitle, DialogDescription } from '@headlessui/vue'
import router from '../router'
import PrimaryButton from './elements/PrimaryButton.vue'
import SecondaryButton from './elements/SecondaryButton.vue'

const user = useUserStore()

const closeModal = () => (user.askingForLogin = false)

const openLoginWindow = () => {
  window.open(`/login?redirect_path=/${router.basePath}?returnFromLogin=true`, 'login', 'status=1,width=380,height=685')
}
</script>

<template>
  <Dialog :open="user.askingForLogin" class="relative z-50" :class="config('app.theme')" @close="closeModal">
    <div class="fixed inset-0 backdrop-blur bg-black/25" aria-hidden="true" />
    <div class="fixed inset-0 flex items-center justify-center p-4">
      <DialogPanel class="w-full max-w-sm rounded-xl bg-white p-8 shadow-xl">
        <DialogTitle class="text-2xl font-bold mb-2">Inloggen</DialogTitle>
        <DialogDescription class="font-secondary normal-case mb-6">
          Om dit te kunnen gebruiken moet je ingelogd zijn. Klik op de knop hieronder om in te loggen.
        </DialogDescription>
        <div class="flex justify-between">
          <SecondaryButton @click="closeModal">Annuleren</SecondaryButton>
          <PrimaryButton @click="openLoginWindow">Inloggen</PrimaryButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>
