import { privacy, api, loadScript } from '@dpgradio/creative'
import jwtDecode from 'jwt-decode'

const PROFILE_SERVICE_URL = 'https://id-mapper-rest-api.data.dpgmedia.cloud/dmoi'
const DMOI_STORAGE_KEY = 'dario-dmoi'
const DMOI_FETCH_TIMEOUT = 1000
const DEFAULT_INVALIDATION_HOUR = 5
const DEFAULT_CACHE_DAYS = 1
const PROFILE_SERVICE_API_KEY = 'XSd67aKWxm5QBaulVCSrQ7CjIlu56HAq8fj9U1Qz'
const STORE_AND_ACCESS_INFORMATION_CONSENT = '1'

export default class DMOIParameters {
  constructor() {
    this.dmoi = null
    this.cxenseId = null
  }

  async getParameters() {
    try {
      const consent = await privacy.waitForConsent()

      if (!consent.purposes.has(STORE_AND_ACCESS_INFORMATION_CONSENT)) {
        return {}
      }
    } catch (error) {
      console.error('Consent string could not be added: ', error)
      return {}
    }

    this.dmoi = this.getFromStorage()

    if (!this.dmoi) {
      try {
        this.cxenseId = await this.fetchCxenseId()
        this.dmoi = await this.fetchDMOI()

        this.cacheDMOI()
      } catch (error) {
        console.error('error with DMOI', error)
        return {}
      }
    }

    return {
      'aw_0_1st.puid': this.dmoi,
    }
  }

  async fetchCxenseId(timeout = 1000) {
    try {
      await loadScript('https://cdn.cxense.com/cx.js', { timeout })
      return window.cX?.getCxenseUserId()
    } catch (error) {
      console.error('Could not load cxense script', error)
      return null
    }
  }
  async fetchDMOI() {
    try {
      const identifiers = this.getIdentifiers()

      if (Object.keys(identifiers).length === 0) {
        return null
      }

      const abortController = new AbortController()
      const abortTimeout = setTimeout(() => {
        abortController.abort('Timed out')
      }, DMOI_FETCH_TIMEOUT)
      const response = await fetch(PROFILE_SERVICE_URL, {
        method: 'POST',
        headers: {
          'x-api-key': PROFILE_SERVICE_API_KEY,
        },
        body: JSON.stringify(identifiers),
        signal: abortController.signal,
      })

      clearTimeout(abortTimeout)

      return (await response.json()).dmoi
    } catch (error) {
      console.error('Could not fetch DMOI', error)
      return null
    }
  }

  getIdentifiers() {
    return Object.fromEntries(
      Object.entries({
        pw_id: this.getCookie('authId'),
        gigya_id: api.radiotoken ? jwtDecode(api.radiotoken).uid : null,
        cxense_id: this.cxenseId,
      }).filter(([, v]) => v)
    )
  }

  async cacheDMOI() {
    localStorage.setItem(
      DMOI_STORAGE_KEY,
      JSON.stringify({
        value: this.dmoi,
        expiration: this.getExpirationDate(),
      })
    )
  }

  getExpirationDate() {
    const currDate = new Date()

    currDate.setDate(currDate.getDate() + DEFAULT_CACHE_DAYS)
    currDate.setUTCHours(DEFAULT_INVALIDATION_HOUR, 0, 0, 0) // DMOI IS MATCHING ACCOUNTS AT 2AM UTC SO WE NEED TO INVALIDATE AT 5AM UTC the get the new DMOI

    return currDate.toISOString()
  }

  getFromStorage() {
    try {
      const storageValue = localStorage.getItem(DMOI_STORAGE_KEY)
      if (!storageValue) return null

      const { expiration, value } = JSON.parse(storageValue)

      return new Date(expiration) >= new Date() ? value : null
    } catch (error) {
      return null
    }
  }

  getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }
}
