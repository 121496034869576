import { api } from '@dpgradio/creative'
import { defineStore } from 'pinia'
import { useUserStore } from './user'

export const useRatingsStore = defineStore('ratings', {
  state: () => ({
    ratings: null,
    ratingsQueue: [],
  }),
  getters: {
    hasLiked() {
      return (track) => this.ratings?.includes(track.selector_code)
    },
  },
  actions: {
    async initialize() {
      const ratings = await api.ratings.allForMember().then((paginator) => paginator.fetchAllPrevious())
      this.ratings = [...ratings.map((rating) => rating.selector_code), ...this.ratingsQueue]
      console.log('A', 'Ratings response', ratings)
      console.log('A', 'Ratings queue', this.ratingsQueue)
      console.log('A', 'Ratings final', this.ratings)
    },
    async toggleLike(track) {
      this.hasLiked(track) ? this.unlike(track) : this.like(track)
    },
    async like(track) {
      try {
        await useUserStore().requireAuthentication()
      } catch (e) {
        console.error('Authentication canceled by the user or failed:', e)
        return
      }

      if (this.ratings) {
        this.ratings.push(track.selector_code)
      } else {
        // If the ratings haven't been loaded yet, we'll queue the like
        this.ratingsQueue.push(track.selector_code)
      }

      console.log('B', 'Ratings this', this.ratings)
      console.log('B', 'Ratings queue', this.ratingsQueue)

      await api.ratings.like(track.selector_code)

      console.log('B', 'Like complete')
    },
    async unlike(track) {
      try {
        await useUserStore().requireAuthentication()
      } catch (e) {
        console.error('Authentication canceled by the user or failed:', e)
        return
      }

      this.ratings = this.ratings.filter((code) => code !== track.selector_code)
      this.ratingsQueue = this.ratingsQueue.filter((code) => code !== track.selector_code)

      console.log('C', 'Ratings this', this.ratings)
      console.log('C', 'Ratings queue', this.ratingsQueue)

      await api.ratings.unlike(track.selector_code)

      console.log('C', 'Unlike complete')
    },
  },
})
