import router from './router'
import { useChannelsStore } from './stores/channels'
import { Mode, useCurrentChannelStore } from './stores/currentChannel'

const parameters = new URLSearchParams(window.location.search)

export const registerRoutes = () => {
  const channelsStore = useChannelsStore()
  const currentChannelStore = useCurrentChannelStore()

  // GET / + GET /{mode}
  router.register(/^(listen|watch)?$/, async (mode = Mode.Listen) => {
    currentChannelStore.switchToMode(mode)
    await currentChannelStore.set(channelsStore.main, parameters.get('autoPlay') !== 'false')
  })

  // GET /{channel_id}/{mode}
  router.register(/^(.+)\/(listen|watch)?$/, async (channelId, mode = Mode.Listen) => {
    currentChannelStore.switchToMode(mode)
    const customChannel =
      channelsStore.channels.find((channel) => channel.station.id === channelId) || channelsStore.main
    await currentChannelStore.set(customChannel, parameters.get('autoPlay') !== 'false')
  })

  // GET /{channel_id}
  router.register(/^(.+)$/, async (channelId) => {
    const customChannel =
      channelsStore.channels.find((channel) => channel.station.id === channelId) || channelsStore.main
    await currentChannelStore.set(customChannel, parameters.get('autoPlay') !== 'false')
  })
}
