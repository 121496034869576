import { privacy, loadScript } from '@dpgradio/creative'
import { setupLogger } from '../util/log'

const logger = setupLogger('AdswizzParameters')

export default class AdswizzParameters {
  constructor() {
    this.parameters = {
      // General player information
      'aw_0_1st.skey': Math.floor(Date.now() / 1000),
      'aw_0_1st.playerid': 'site-player',
    }

    this.cookieSynced = undefined
  }

  async getParameters() {
    const waitForConsent = this.addConsentParameters()
    const waitForListenerId = this.addListenerIdParameter()

    await waitForConsent
    await waitForListenerId

    return this.parameters
  }

  async addConsentParameters() {
    try {
      const consent = await privacy.waitForConsent()

      this.parameters['aw_0_req.userConsentV2'] = consent.consentString
    } catch (error) {
      logger.error('Consent string could not be added', error)
    }
  }

  async addListenerIdParameter() {
    if (await this.loadRegisterScript()) {
      // Pass listenerid gotten from the register2 script
      this.parameters['listenerid'] = window.com_adswizz_synchro_listenerid
    }
  }

  async loadRegisterScript() {
    if (this.cookieSynced === undefined) {
      try {
        await loadScript('https://synchrobox.adswizz.com/register2.php', { timeout: 1000 })
        this.cookieSynced = true
      } catch (error) {
        this.cookieSynced = false
        logger.error('Register script could not be loaded', error)
      }
    }
    return this.cookieSynced
  }
}
