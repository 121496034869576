import AdswizzParameters from './AdswizzParameters'
import DMOIParameters from './DMOIParameters'
import CxenseParameters from './CxenseParameters'

function parameterProviders(adServer) {
  switch (adServer) {
    case 'none':
      return []
    default:
      return [new AdswizzParameters(), new DMOIParameters(), new CxenseParameters()]
  }
}

let parametersCache = {}

export default async function collectParameters(adServer) {
  if (!parametersCache[adServer]) {
    const parameterLists = await Promise.all(parameterProviders(adServer).map((provider) => provider.getParameters()))
    parametersCache[adServer] = parameterLists.reduce((acc, list) => ({ ...acc, ...list }), {})
  }
  return parametersCache[adServer]
}
