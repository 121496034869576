import { Notifier as AirbrakeNotifier } from '@airbrake/browser'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { privacy, dataLayer, configuration, config, setupAirbrake } from '@dpgradio/creative'
import router from './router'
import { registerRoutes } from './routes'
import loadMyChannelsSdk from './util/loadMyChannelsSdk'
import initializeSiteCommunication from './siteCommunication'
import { initializeTrackingObserver } from './trackingObserver'
import initializeRadioTokenExchange from './radioTokenExchange'
import { useChannelsStore } from './stores/channels'
import './style.css'
import App from './App.vue'

const parameters = new URLSearchParams(window.location.search)

if (parameters.has('returnFromLogin')) {
  window.close()
}

const initialize = async () => {
  await configuration.retrieveConfigForDetectedStation('player')

  const pinia = createPinia()
  const app = createApp(App)

  if (import.meta.env.PROD) {
    const airbrake = await setupAirbrake(
      AirbrakeNotifier,
      {
        projectId: 489812,
        projectKey: 'c1cdb76fcf7730a202bd5589ec6dbf9f',
        version: import.meta.env.VITE_COMMIT_HASH,
        environment: 'development',
      },
      app
    )
    airbrake.addFilter((notice) =>
      ['cxense', 'Cxense', 'adswizz', 'Adswizz'].some((phrase) => notice.errors[0]?.message?.includes(phrase))
        ? null
        : notice
    )
  }

  app.use(pinia)
  app.mount('#app')

  await useChannelsStore().initialize()

  privacy.initialize()
  dataLayer.initialize()
  dataLayer.pushVirtualPageView()

  privacy.pushFunctional(() => loadMyChannelsSdk(config('app.mychannels_brand')))

  initializeTrackingObserver()

  registerRoutes()
  await router.start()

  initializeRadioTokenExchange()
  initializeSiteCommunication()
}

initialize()
