<script setup>
import { computed } from 'vue'
import { useChannelsStore } from '../../stores/channels'
import { Mode, useCurrentChannelStore } from '../../stores/currentChannel'
import { useNowPlayingStore } from '../../stores/nowPlaying'
import router from '../../router'
import ShowBanner from './ShowBanner.vue'
import ChannelBanner from './ChannelBanner.vue'
import PrimaryButton from '../elements/PrimaryButton.vue'
import WatchIcon from '../../assets/icons/watch.svg'
import ListenIcon from '../../assets/icons/listen.svg'

const channels = useChannelsStore()
const currentChannel = useCurrentChannelStore()
const nowPlaying = useNowPlayingStore()

const program = computed(() => nowPlaying.programs[currentChannel.stationId])

const switchToMode = (mode) => router.navigateTo(currentChannel.stationId, mode)
</script>

<template>
  <div class="w-full h-24 px-4 md:px-6 flex justify-between items-center md:items-end border-b border-gray-100">
    <div class="w-40 joe:w-48 joe-be:w-48">
      <img :src="channels.main?.station?.logo?.app_logo" class="md:hidden h-20" />
    </div>
    <div class="hidden md:block w-[32rem] h-full relative overflow-hidden">
      <Transition name="slide-down" mode="out-in">
        <div :key="currentChannel.stationId" class="absolute z-10 bottom-0 w-full pb-1.5 -mb-[1px]">
          <ShowBanner v-if="program" :program="program" />
          <ChannelBanner v-if="!program && currentChannel.channel" :channel="currentChannel.channel" />
        </div>
      </Transition>
      <Transition name="fade">
        <div
          :key="currentChannel.stationId"
          class="absolute z-20 bottom-0 w-full h-1.5 rounded-t-md -mb-[1px] willy:rounded-none"
          :style="{ backgroundColor: currentChannel.foregroundColor }"
        />
      </Transition>
    </div>
    <div class="flex-1 md:flex-none w-40 joe:w-48 joe-be:w-48 h-full">
      <div v-if="currentChannel.supportsWatch" class="h-full flex items-center justify-end">
        <PrimaryButton
          v-if="currentChannel.mode === Mode.Watch"
          :icon="ListenIcon"
          title="Schakel naar luisteren zonder beeld"
          @click="switchToMode(Mode.Listen)"
        >
          Luister Live
        </PrimaryButton>
        <PrimaryButton
          v-else
          :icon="WatchIcon"
          title="Schakel naar luisteren met beeld"
          @click="switchToMode(Mode.Watch)"
        >
          Kijk Live
        </PrimaryButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slide-down-enter-active,
.slide-down-leave-active {
  @apply transition-transform duration-200 ease-in;
}
.slide-down-enter-from,
.slide-down-leave-to {
  @apply translate-y-full;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-300 ease-in;
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
