<script setup>
import { computed } from 'vue'
import { useUserStore } from '../../stores/user'
import { useLoadingFeedback } from '../../util/useLoadingFeedback'
import CircleButton from '../elements/CircleButton.vue'
import SpinIcon from '../../assets/icons/spin.svg'
import HeartIcon from '../../assets/icons/heart.svg'
import ErrorIcon from '../../assets/icons/error.svg'

const props = defineProps({
  track: {
    type: Object,
    required: true,
  },
})

const user = useUserStore()
const liked = computed(() => user.ratings.hasLiked(props.track))

const { loading, error, execute: toggleLike } = useLoadingFeedback(() => user.ratings.toggleLike(props.track))
</script>

<template>
  <CircleButton
    class="group/like hover:bg-primary joe:group/like hover:bg-secondary hover:bg-opacity-100 text-primary hover:text-white"
    :title="liked ? 'Unlike deze track' : 'Like deze track'"
    @click="toggleLike"
  >
    <SpinIcon v-if="loading" class="w-5 h-5" />
    <ErrorIcon v-else-if="error" class="w-5 h-5" />
    <HeartIcon
      v-else
      class="w-5 h-5 transition-all duration-300"
      :class="liked ? 'stroke-none' : 'stroke-primary joe:stroke-secondary group-hover/like:stroke-white fill-none'"
    />
  </CircleButton>
</template>
