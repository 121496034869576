<script setup>
import { useChannelsStore } from '../../stores/channels'
import { useCurrentChannelStore } from '../../stores/currentChannel'
import ChannelOption from './ChannelOption.vue'
import ChannelSelector from './ChannelSelector.vue'

const channels = useChannelsStore()
const currentChannel = useCurrentChannelStore()
</script>

<template>
  <div
    class="md:h-screen group bg-white shadow-t-xl md:shadow-r-2xl w-full md:w-28 md:hover:w-96 md:py-2 flex md:flex-col transition-[width] duration-300 rounded-t-xl md:rounded-none overflow-hidden bg-background"
  >
    <div class="hidden md:flex w-full justify-between items-center overflow-hidden">
      <div class="flex-1 md:min-w-[14rem]">
        <img :src="channels.main?.station?.logo?.app_logo" class="w-24 mx-2" />
      </div>
    </div>
    <div v-if="currentChannel.channel" class="p-4 md:p-6 border-r md:border-r-0 md:border-b border-gray-100 relative">
      <div
        class="absolute top-0 md:top-auto md:right-0 w-16 h-1.5 md:w-1.5 md:h-16 rounded-b md:rounded-l md:rounded-br-none willy:rounded-none"
        :style="{ backgroundColor: currentChannel.foregroundColor }"
      />
      <ChannelOption :channel="currentChannel.channel" :showPlayButton="false" />
    </div>
    <div class="relative">
      <div
        class="absolute top-0 left-0 z-10 w-4 h-full md:w-full md:h-6 bg-gradient-to-r md:bg-gradient-to-b from-white willy:bg-none"
      />
    </div>
    <div class="p-4 md:p-6 overflow-x-auto md:overflow-y-auto flex md:flex-col">
      <ChannelSelector class="flex md:block pr-2 md:pr-0" />
    </div>
    <div
      class="absolute top-0 md:top-auto md:bottom-0 right-0 w-6 h-full md:w-full md:h-6 bg-gradient-to-l md:bg-gradient-to-t from-white willy:bg-none"
    />
  </div>
</template>
