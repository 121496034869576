<script setup>
import { computed } from 'vue'
import { useCurrentChannelStore } from '../../stores/currentChannel'
import { useNowPlayingStore } from '../../stores/nowPlaying'
import HorizontalTrack from './HorizontalTrack.vue'
import PlaylistIcon from '../../assets/icons/playlist.svg'

const currentChannel = useCurrentChannelStore()
const nowPlaying = useNowPlayingStore()

const previousTrack = computed(() => nowPlaying.previousTrack(currentChannel.stationId))
const currentTrack = computed(() => nowPlaying.currentTrack(currentChannel.stationId))
const nextTrack = computed(() => nowPlaying.nextTrack(currentChannel.stationId))
</script>

<template>
  <div class="space-y-6">
    <div>
      <a
        :href="currentChannel.playlistUrl"
        target="_blank"
        class="group transition-all duration-300 flex items-center justify-end text-primary hover:opacity-75 space-x-2 pr-8 mb-2 joe-be:text-primary-alt"
      >
        <PlaylistIcon class="w-5 h-5" />
        <span class="text-lg">Check vorige tracks</span>
      </a>

      <HorizontalTrack v-if="previousTrack" :track="previousTrack" label="Vorige" />
    </div>

    <div
      v-if="currentTrack"
      class="flex items-center bg-primary bg-opacity-5 p-3 pr-0 rounded-l-xl relative joe:bg-opacity-20 joe-be:bg-opacity-20"
    >
      <div class="absolute right-0 w-3 h-28 rounded-l-lg bg-primary" />
      <HorizontalTrack :track="currentTrack" :large="true" class="flex-1" />
    </div>

    <HorizontalTrack v-if="nextTrack" :track="nextTrack" label="Volgende" />
  </div>
</template>
