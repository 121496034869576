let unlocked = false

const addListeners = (callback) => {
  const callbackWithRemove = () => {
    callback()
    removeListeners(callbackWithRemove)
  }
  document.body.addEventListener('click', callbackWithRemove, true)
  document.body.addEventListener('touchstart', callbackWithRemove, true)
  document.body.addEventListener('touchend', callbackWithRemove, true)
  document.body.addEventListener('keydown', callbackWithRemove, true)
}

const removeListeners = (callback) => {
  document.body.removeEventListener('click', callback, true)
  document.body.removeEventListener('touchstart', callback, true)
  document.body.removeEventListener('touchend', callback, true)
  document.body.removeEventListener('keydown', callback, true)
}

export default function onUnlock(callback) {
  if (unlocked) {
    callback()
  } else {
    addListeners(callback)
  }
}
