import { Howl, Howler } from 'howler'
import { defineStore } from 'pinia'
import { setupLogger } from '../util/log'

const logger = setupLogger('audioPlayer')

const State = {
  Loading: 'loading',
  Playing: 'playing',
  Stopped: 'stopped',
}

export const useAudioPlayerStore = defineStore('audioPlayer', {
  state: () => ({
    player: null,
    playingItemId: undefined,
    state: State.Loading,
    muted: false,
    volume: 1,
    errors: [],
  }),
  getters: {
    isPlaying() {
      return this.state === State.Playing
    },
    isStopped() {
      return this.state === State.Stopped
    },
    isLoading() {
      return this.state === State.Loading
    },
  },
  actions: {
    stream(...urls) {
      this.createPlayer(...urls)
      this.play()
    },
    createPlayer(...urls) {
      logger.info(`Create player with (${urls.length} urls) `)

      Howler.unload()

      // Create new player
      try {
        this.player = new Howl({
          src: urls,
          html5: true,
        })
        this.registerCallbacks()
        this.state = State.Stopped
        this.playingItemId = undefined
      } catch (error) {
        logger.error(`Error creating new player:`, error)
        this.errors.push(`Error creating new player: ${error}`)
      }
    },
    play() {
      if (!this.player.playing()) {
        this.updateState(this.playingItemId, State.Loading)
        this.playingItemId = this.player.play(this.playingItemId)
      }
    },
    stop() {
      this.player.stop(this.playingItemId)
    },
    toggleMute() {
      Howler.mute(!this.muted)
      this.muted = !this.muted
    },
    setVolume(volume) {
      if (this.muted) {
        Howler.mute(false)
        this.muted = false
      }
      Howler.volume(volume)
      this.volume = volume
    },
    turnOff() {
      if (this.player) {
        this.player.off('unlock')
        this.stop()
      }
    },
    registerCallbacks() {
      this.player.on('load', () => {
        logger.info('Stream url loaded')
      })
      this.player.on('loaderror', (id, error) => {
        logger.error(`Load error (${id}):`, error)
        this.errors.push(`Load error: ${error}`)

        this.updateState(id, State.Stopped)
      })
      this.player.on('playerror', (id, error) => {
        logger.error(`Play error (${id}):`, error)
        this.errors.push(`Play error: ${error}`)

        this.updateState(id, State.Stopped)
        this.registerUnlockHandler()
      })
      this.player.on('play', (id) => {
        logger.info(`Started playing (${id})`)
        this.updateState(id, State.Playing)
      })
      this.player.on('end', (id) => {
        logger.info(`Ended (${id})`)
        this.updateState(id, State.Stopped)
      })
      this.player.on('stop', (id) => {
        logger.info(`Stopped (${id})`)
        this.updateState(id, State.Stopped)
      })
    },
    updateState(id, state) {
      if (id === this.playingItemId) {
        this.state = state
      }
    },
    registerUnlockHandler() {
      this.player.once('unlock', () => {
        logger.info(`Player unlocked after page interaction`)
        if (!this.player.playing()) {
          this.updateState(this.playingItemId, State.Loading)
          this.player.play(this.playingItemId)
        }
      })
    },
  },
})
