import { v1 as uuidv1 } from 'uuid'
import { dataLayer } from '@dpgradio/creative'
import { useChannelsStore } from './stores/channels'
import { Mode, useCurrentChannelStore } from './stores/currentChannel'
import { useNowPlayingStore } from './stores/nowPlaying'
import { useRatingsStore } from './stores/ratings'
import { watch } from 'vue'

// Following the spec from https://atlassian.dpgmedia.net/confluence/display/TBN/Events+Radioplayer

export const initializeTrackingObserver = () => {
  const currentChannel = useCurrentChannelStore()
  const nowPlaying = useNowPlayingStore()
  const ratings = useRatingsStore()

  // Heartbeat
  setInterval(() => {
    // TODO: Verify if we only need to send these events when in listen mode.
    if (currentChannel.mode === Mode.Listen && currentChannel.player.isPlaying) {
      pushEvent('player_heartbeat')
    }
  }, 60000)

  // Player playing state
  let notifiedOfStreamStart = false
  onPlayerPropertyChange('state', (player) => {
    if (player.isPlaying) {
      pushEvent('player_play')

      if (!notifiedOfStreamStart) {
        pushEvent('player_stream_start')
        notifiedOfStreamStart = true
      }
    } else if (player.isPaused || player.isStopped) {
      pushEvent('player_stop')
    }
  })

  // Player volume
  onPlayerPropertyChange('volume', (player, newVolume, oldVolume) => {
    const volumeData = (data) => {
      data.player_volume_change = Math.abs(newVolume - oldVolume)
    }
    if (newVolume > oldVolume) {
      pushEvent('player_volume_up', volumeData)
    } else if (newVolume < oldVolume) {
      pushEvent('player_volume_down', volumeData)
    }
  })

  // Player mute
  onPlayerPropertyChange('muted', (player) => {
    player.muted ? pushEvent('player_mute') : pushEvent('player_unmute')
  })

  // Player errors
  onPlayerPropertyChange('errors', (player) => {
    if (player.errors.length > 0) {
      pushEvent('player_error', (data) => {
        data.player_error_message = player.errors[player.errors.length - 1]
      })
    }
  })

  // Channel switches
  watch(
    () => currentChannel.channel,
    (newChannel, oldChannel) => oldChannel && pushEvent('player_channel_changed')
  )

  // Track starts and ends
  watch(
    () => [nowPlaying.currentTrack(currentChannel.stationId), currentChannel.player.state],
    ([newTrack, newState], [oldTrack, oldState]) => {
      // TODO: Verify if we only need to send these events when in listen mode.
      if (currentChannel.mode !== Mode.Listen || !currentChannel.player.isPlaying) {
        return
      }
      const wasAlreadyPlaying = oldState == newState
      if (newTrack == oldTrack && wasAlreadyPlaying) {
        return
      }
      if (oldTrack && wasAlreadyPlaying) {
        pushEvent('player_track_ended', (data) => {
          data.player_content_id = oldTrack?.selector_code
          data.player_content_title = oldTrack?.title
          data.player_artist_name = oldTrack?.artist?.name
          data.player_artist_id = oldTrack?.artist?.id
        })
      }
      pushEvent('player_track_started')
    }
  )

  // Like and dislike
  watch(
    () => JSON.parse(JSON.stringify(ratings.ratings)),
    (newRatings, oldRatings) => {
      if (oldRatings === null) {
        return
      }
      const addedLikes = newRatings.filter((x) => !oldRatings.includes(x))
      const addedUnlikes = oldRatings.filter((x) => !newRatings.includes(x))

      const pushLikeEvent = (name, trackId) => {
        const track = nowPlaying.plays[currentChannel.stationId].find((track) => track.selector_code === trackId)
        pushEvent(name, (data) => {
          data.player_track_id = trackId
          data.player_track_title = track?.title
          data.player_artist_name = track?.artist?.name
          data.player_artist_id = track?.artist?.id
        })
      }

      addedLikes.forEach((trackId) => pushLikeEvent('like', trackId))
      addedUnlikes.forEach((trackId) => pushLikeEvent('dislike', trackId))
    }
  )
}

const sessionId = `${uuidv1().replace(/-/gi, '')}.${new Date().getTime()}`

const pushEvent = (event, customizeData = (d) => d) => {
  const channels = useChannelsStore()
  const currentChannel = useCurrentChannelStore()
  const nowPlaying = useNowPlayingStore()

  const currentTrack = nowPlaying.currentTrack(currentChannel.stationId)
  const program = nowPlaying.programs[currentChannel.stationId]

  const data = {
    player_content_id: currentTrack?.selector_code,
    player_content_title: currentTrack?.title,
    player_artist_name: currentTrack?.artist?.name,
    player_artist_id: currentTrack?.artist?.id,
    player_radio_station: channels.main?.station?.id,
    player_program_title: program?.title,
    player_channel: currentChannel.station?.name,
    player_version: 2023,
    player_session_id: sessionId,
    player_playing: currentChannel.player?.isPlaying,
    player_volume: currentChannel.player?.volume,
    player_error_id: null, // Error ID is never actually set in the old player
    player_error_message: null,
    player_content_type: 'audio',
    player_livebroadcast: true,
  }

  customizeData(data)

  dataLayer.pushEvent(event, data)
}

const onPlayerPropertyChange = (property, callback) => {
  const currentChannel = useCurrentChannelStore()

  watch(
    () => currentChannel.player[property],
    (newValue, oldValue) => {
      // TODO: Verify if we only need to send these events when in listen mode.
      if (currentChannel.mode === Mode.Listen) {
        callback(currentChannel.player, newValue, oldValue)
      }
    }
  )
}
