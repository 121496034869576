<script setup>
import { config } from '@dpgradio/creative'

const promoBannerUrl = config('app.promo_banner_url')
const promoBannerImage = config('app.promo_banner_image')
const showBanner = !!promoBannerUrl && !!promoBannerImage
</script>

<template>
  <a v-if="showBanner" :href="promoBannerUrl" target="_blank" class="h-28 w-full mt-6 flex items-center">
    <img :src="promoBannerImage" class="w-full h-full block object-contain" />
  </a>
</template>
