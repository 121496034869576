<script setup>
import { useAudioPlayerStore } from '../stores/audioPlayer'
import PlayerControls from './PlayerControls.vue'

const audioPlayer = useAudioPlayerStore()
</script>

<template>
  <PlayerControls :player="audioPlayer" />
</template>
