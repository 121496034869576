<script setup>
import { watch, computed } from 'vue'
import { config } from '@dpgradio/creative'
import { Mode, useCurrentChannelStore } from './stores/currentChannel'
import { useChannelsStore } from './stores/channels'
import Sidebar from './components/sidebar/Sidebar.vue'
import TopBar from './components/topbar/TopBar.vue'
import ListenView from './components/ListenView.vue'
import WatchView from './components/WatchView.vue'
import Login from './components/Login.vue'

const currentChannel = useCurrentChannelStore()
const channels = useChannelsStore()

watch(
  () => currentChannel.channel,
  () => {
    const title = [
      channels.main?.station?.name,
      currentChannel.channel?.id !== channels.main?.id && currentChannel.station?.name,
      'Luisteren',
    ]
    document.title = title.filter(Boolean).join(' - ')
  }
)

const inListenMode = computed(() => currentChannel.mode === Mode.Listen)
const inWatchMode = computed(() => currentChannel.mode === Mode.Watch)
</script>

<template>
  <div :class="config('app.theme')">
    <Sidebar class="absolute z-50 bottom-0" />
    <div v-if="currentChannel.channel" class="pb-28 md:pb-0 md:ml-28 h-screen flex flex-col bg-background">
      <TopBar />
      <div class="flex-1 relative w-full">
        <Transition name="fade">
          <ListenView v-if="inListenMode" :key="currentChannel.stationId" class="absolute w-full h-full" />
          <WatchView v-else-if="inWatchMode" class="absolute w-full" />
        </Transition>
      </div>
    </div>
  </div>
  <Login />
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-500 ease-in;
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
