<script setup>
import { computed } from 'vue'
import { useCurrentChannelStore } from '../../stores/currentChannel'
import { useNowPlayingStore } from '../../stores/nowPlaying'
import VerticalTrack from './VerticalTrack.vue'

const currentChannel = useCurrentChannelStore()
const nowPlaying = useNowPlayingStore()

const previousTrack = computed(() => nowPlaying.previousTrack(currentChannel.stationId))
const currentTrack = computed(() => nowPlaying.currentTrack(currentChannel.stationId))
const nextTrack = computed(() => nowPlaying.nextTrack(currentChannel.stationId))
</script>

<template>
  <div class="w-full px-6 flex justify-center items-center [&>*]:transition-all [&>*]:duration-300">
    <VerticalTrack :track="previousTrack" label="Vorige" :showHistory="true" />
    <VerticalTrack :track="currentTrack" :large="true" class="lg:mx-8 xl:mx-20 2xl:mx-40" />
    <VerticalTrack :track="nextTrack" label="Volgende" />
  </div>
</template>
