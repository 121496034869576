import { config } from '@dpgradio/creative'
import { defineStore } from 'pinia'
import collectParameters from '../parameters/parameters'
import { useAudioPlayerStore } from './audioPlayer'
import { useVideoPlayerStore } from './videoPlayer'

export const Mode = {
  Listen: 'listen',
  Watch: 'watch',
}

const MAX_URL_LENGTH = 9000

export const useCurrentChannelStore = defineStore('currentChannel', {
  state: () => ({
    channel: null,
    mode: Mode.Listen,
  }),
  getters: {
    async streamUrls() {
      if (!this.channel) {
        return []
      }
      const primaryStream = this.channel.station.streams.aac?.[0]

      const parameters = await collectParameters(primaryStream?.options?.ad_server)
      const queryString = new URLSearchParams(parameters).toString()

      return [...(this.channel.station.streams.aac || []), ...(this.channel.station.streams.mp3 || [])]
        .map((stream) => `${stream.source}?${queryString}`)
        .map((stream) => stream.substring(0, MAX_URL_LENGTH))
    },
    station() {
      return this.channel?.station
    },
    stationId() {
      return this.station?.id
    },
    supportsWatch() {
      return !!this.myChannelsId
    },
    myChannelsId() {
      return this.station?.streams?.radioplayerId?.[0]?.source
    },
    foregroundColor() {
      return this.channel?.color?.foreground
    },
    player() {
      if (this.mode == Mode.Watch) {
        return useVideoPlayerStore()
      }
      return useAudioPlayerStore()
    },
    playlistUrl() {
      return `${config('app.playlist_url').replace(/\/$/, '')}/${this.channel?.station?.id}`
    },
  },
  actions: {
    async set(channel, play = true) {
      this.channel = channel

      if (!this.supportsWatch) {
        this.mode = Mode.Listen
      }

      if (this.mode == Mode.Listen) {
        const urls = await this.streamUrls
        play ? useAudioPlayerStore().stream(...urls) : useAudioPlayerStore().createPlayer(...urls)
      }
    },
    switchToWatch() {
      useAudioPlayerStore().turnOff()

      this.mode = Mode.Watch
    },
    async switchToListen() {
      this.mode = Mode.Listen

      this.channel && useAudioPlayerStore().stream(...(await this.streamUrls))
    },
    async switchToMode(mode) {
      if (mode === Mode.Watch) {
        await this.switchToWatch()
      } else {
        await this.switchToListen()
      }
    },
  },
})
