import { defineStore } from 'pinia'
import isMobile from '../util/isMobile'
import { setupLogger } from '../util/log'

const logger = setupLogger('videoPlayer')

const State = {
  Loading: 'loading',
  Paused: 'paused',
  Playing: 'playing',
  Stopped: 'stopped',
}

export const useVideoPlayerStore = defineStore('videoPlayer', {
  state: () => ({
    player: document.createElement('video'),
    playerContainer: null,
    state: State.Loading,
    muted: false,
    volume: 1,
    fullScreen: false,
    inPip: false,
    autoStartError: null,
    errors: [],
  }),
  getters: {
    isPlaying() {
      return this.state === State.Playing
    },
    isStopped() {
      return this.state === State.Stopped
    },
    isPaused() {
      return this.state === State.Paused
    },
    isLoading() {
      return this.state === State.Loading
    },
    pipSupported() {
      return !!this.player.requestPictureInPicture
    },
  },
  actions: {
    setPlayer(videoDomElement, containerDomElement) {
      this.player = videoDomElement
      this.playerContainer = containerDomElement

      this.player.defaultMuted = false
      this.player.volume = this.volume

      this.registerCallbacks()
      this.play()

      if (isMobile()) {
        this.player.playsInline = true
        this.player.controls = true
      }
    },
    async play() {
      this.state = State.Loading
      try {
        await this.player.play()
      } catch (error) {
        logger.error(`Play error:`, error)
        this.errors.push(`Play error: ${error}`)
        this.autoStartError = error
        this.state = State.Stopped
      }
    },
    pause() {
      this.player.pause()
    },
    stop() {
      // The video player cannot be stopped, so we pause it instead.
      this.pause()
    },
    toggleMute() {
      this.player.muted = !this.player.muted
    },
    setVolume(volume) {
      if (this.muted) {
        this.player.muted = false
      }
      this.player.volume = volume
    },
    enterFullScreen() {
      if (this.playerContainer.requestFullscreen) {
        this.playerContainer.requestFullscreen()
      } else if (this.player.mozRequestFullScreen) {
        this.playerContainer.mozRequestFullScreen()
      } else if (this.player.webkitRequestFullscreen) {
        this.playerContainer.webkitRequestFullscreen()
      } else if (this.playerContainer.msRequestFullscreen) {
        this.playerContainer.msRequestFullscreen()
      }
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    toggleFullScreen() {
      this.fullScreen ? this.exitFullScreen() : this.enterFullScreen()
    },
    togglePip() {
      if (this.inPip) {
        this.exitPip()
      } else {
        this.player.requestPictureInPicture()
      }
    },
    exitPip() {
      document.exitPictureInPicture()
    },
    registerCallbacks() {
      this.player.onplay = () => {
        this.state = State.Playing
        this.autoStartError = null
      }
      this.player.onpause = () => {
        this.state = State.Paused
      }
      this.player.onabort = () => {
        this.state = State.Stopped
      }
      this.player.onvolumechange = () => {
        this.volume = this.player.volume
        this.muted = this.player.muted
      }
      this.player.onerror = (error) => {
        logger.error(`Player error:`, error)
        this.errors.push(`Player error: ${error}`)
      }
      this.player.onenterpictureinpicture = () => {
        this.inPip = true
      }
      this.player.onleavepictureinpicture = () => {
        this.inPip = false
      }

      const prefixes = ['', 'moz', 'webkit', 'ms']
      prefixes.forEach((prefix) => {
        document.addEventListener(prefix + 'fullscreenchange', () => {
          const fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement

          this.fullScreen = fullscreenElement === this.playerContainer
        })
      })
    },
  },
})
