<script setup>
import { computed } from 'vue'

const props = defineProps({
  track: {
    type: Object,
    default: null,
  },
})

const position = computed(() => props.track?.active_editions?.[0]?.position)
</script>

<template>
  <span v-if="position" class="z-30 w-fit h-8 text-md inline-block py-1 px-2 rounded bg-primary text-primary-alt">
    #{{ position }}
  </span>
</template>
