<script setup>
import { useNowPlayingStore } from '../../stores/nowPlaying'
import CircleButton from '../elements/CircleButton.vue'
import PlayIcon from '../../assets/icons/play.svg'
import SpeakerIcon from '../../assets/icons/speaker.svg'

const nowPlaying = useNowPlayingStore()

defineProps({
  channel: {
    type: Object,
    required: true,
  },
  showPlayButton: {
    type: Boolean,
    default: true,
  },
})
</script>

<template>
  <div
    class="rounded-md w-16 md:group-hover:w-full overflow-hidden transition-all duration-300 hover:opacity-90 willy:rounded-none"
  >
    <div
      class="h-16 w-full py-2 pl-20 pr-3 bg-[length:auto_105%] bg-left-top flex items-center relative"
      :style="{
        backgroundImage: `url(${channel.station.logo.site_background})`,
      }"
    >
      <div class="absolute top-0 left-0 w-16 h-16 p-1">
        <img :src="channel.station.logo.site_logo" class="w-full h-full object-cover" />
      </div>
      <div
        class="flex-1 h-full flex flex-col justify-center text-white joe-be:!text-white joe:!text-white willy:!text-white"
        :style="{ color: channel.color.extra }"
      >
        <div class="line-clamp-1 text-xl leading-none font-semibold joe-be:mb-1 joe:mb-1 willy:mb-1">
          {{ nowPlaying.currentTrack(channel.station.id)?.title }}
        </div>
        <div class="line-clamp-1 text-sm leading-none font-secondary joe:uppercase joe-be:uppercase">
          {{ nowPlaying.currentTrack(channel.station.id)?.artist?.name }}
        </div>
      </div>
      <CircleButton v-if="showPlayButton" title="Afspelen">
        <PlayIcon class="h-4 w-4 text-primary willy:hidden" />
        <SpeakerIcon class="h-4 w-4 text-primary-alt hidden willy:block" />
      </CircleButton>
    </div>
  </div>
</template>
