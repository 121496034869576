<script setup>
import { watch, onMounted, onUnmounted, ref, computed } from 'vue'
import checkIsMobile from '../util/isMobile'
import { useCurrentChannelStore } from '../stores/currentChannel'
import { useVideoPlayerStore } from '../stores/videoPlayer'
import onUnlock from '../util/onUnlock'
import { setupLogger } from '../util/log'
import CircleButton from './elements/CircleButton.vue'
import SecondaryButton from './elements/SecondaryButton.vue'
import PlayerControls from './PlayerControls.vue'
import PipIcon from '../assets/icons/pip.svg'
import FullscreenIcon from '../assets/icons/fullscreen.svg'

const logger = setupLogger('videoPlayerComponent')

const currentChannel = useCurrentChannelStore()
const videoPlayer = useVideoPlayerStore()

const componentElement = ref(null)
const myChannelsPlayer = ref(null)

const isMobile = checkIsMobile()

const showMyChannelsPlayer = ref(false)
const manualShowTimeout = setTimeout(() => {
  if (!showMyChannelsPlayer.value && !videoPlayer.autoStartError) {
    logger.error(
      'Player was not shown after 4 seconds. It is likely that the HTML structure of the MyChannels player has changed. Falling back to a manual show.'
    )
    showMyChannelsPlayer.value = true
  }
}, 4000)
onUnmounted(() => {
  clearTimeout(manualShowTimeout)
})

watch(
  () => videoPlayer.state,
  () => {
    if (videoPlayer.isPlaying) {
      // Hide the thumbnail in the MyChannels player
      try {
        document.querySelector('.pui__view__start').style.display = 'none'
      } catch (e) {
        logger.error('Could not hide the thumbnail in the MyChannels player.')
      }
    }
  }
)

watch(
  () => videoPlayer.autoStartError,
  () => videoPlayer.autoStartError && onUnlock(videoPlayer.play)
)

const watchVideoPlayerAvailability = () => {
  const observer = new MutationObserver(() => {
    if (!myChannelsPlayer.value) {
      // When switching between the audio and video player there are brief moments where mychannels player element does not exist anymore.
      return
    }
    const videoPlayerDomElement = myChannelsPlayer.value.querySelector('div.pe__container__player > video')
    if (videoPlayerDomElement) {
      videoPlayer.setPlayer(videoPlayerDomElement, componentElement.value)
      observer.disconnect()
    }
  })
  observer.observe(myChannelsPlayer.value, { childList: true, subtree: true })
}

const watchMyChannelsPlayButtonAvailability = () => {
  const observer = new MutationObserver(() => {
    if (!myChannelsPlayer.value) {
      // When switching between the audio and video player there are brief moments where mychannels player element does not exist anymore.
      return
    }
    const visiblePlayButton = myChannelsPlayer.value.querySelector('.pui__overlay__playbutton_wrapper')
    if (visiblePlayButton) {
      showPlayerOnceMyChannelsPlayButtonIsGone(visiblePlayButton)
      observer.disconnect()
    }
  })
  observer.observe(myChannelsPlayer.value, { childList: true, subtree: true })
}

const showPlayerOnceMyChannelsPlayButtonIsGone = (button) => {
  const observer = new MutationObserver(() => {
    showMyChannelsPlayer.value = true
  })
  observer.observe(button, { attributes: true })
}

onMounted(() => {
  watchVideoPlayerAvailability()
  watchMyChannelsPlayButtonAvailability()
})

const showPlayerContainer = computed(
  () => showMyChannelsPlayer.value && !videoPlayer.autoStartError && (isMobile || !videoPlayer.inPip)
)

const showOverlayControls = ref(false)
onMounted(() => {
  let timeout = null
  componentElement.value.firstChild.addEventListener('mousemove', () => {
    clearTimeout(timeout)
    showOverlayControls.value = true
    timeout = setTimeout(() => {
      showOverlayControls.value = false
    }, 3000)
  })
  componentElement.value.firstChild.addEventListener('mouseleave', () => {
    clearTimeout(timeout)
    showOverlayControls.value = false
  })
})
</script>

<template>
  <div>
    <div ref="componentElement" class="fullscreen:h-full fullscreen:flex flex-col justify-center fullscreen:bg-black">
      <div class="aspect-video flex items-center">
        <div
          class="mx-auto overflow-hidden shadow-2xl transition-all duration-300 bg-primary text-primary-alt"
          :class="{
            'w-full md:rounded': showPlayerContainer,
            'w-64 rounded-3xl': !showPlayerContainer,
          }"
        >
          <div v-if="!showPlayerContainer" class="px-6 py-4 text-lg flex items-center justify-center">
            <div v-if="videoPlayer.inPip">
              Je kijkt in minispeler modus.
              <SecondaryButton class="block mx-auto" @click="videoPlayer.exitPip()">Sluiten</SecondaryButton>
            </div>
            <div v-else-if="videoPlayer.autoStartError" class="cursor-pointer">Klik hier om te starten.</div>
            <div v-else-if="!showMyChannelsPlayer">Laden...</div>
          </div>
          <div v-show="showPlayerContainer" class="relative group aspect-video">
            <div
              v-if="!isMobile"
              class="absolute bottom-0 z-50 w-full h-1/2 px-5 pb-5 flex items-end justify-end transition-opacity duration-300 fullscreen:bg-gradient-to-t from-black"
              :class="showOverlayControls ? 'opacity-100' : 'opacity-0'"
            >
              <PlayerControls :player="videoPlayer" class="hidden fullscreen:flex flex-row-reverse items-baseline" />
              <div class="flex space-x-2">
                <CircleButton
                  v-if="videoPlayer.pipSupported"
                  title="Minispeler"
                  class="fullscreen:hidden"
                  @click="videoPlayer.togglePip()"
                >
                  <PipIcon class="h-5 w-5" />
                </CircleButton>
                <CircleButton title="Volledig scherm" @click="videoPlayer.toggleFullScreen()">
                  <FullscreenIcon class="h-5 w-5" />
                </CircleButton>
              </div>
            </div>
            <div
              ref="myChannelsPlayer"
              class="mychannels fullscreen:h-full"
              data-mychannels-type="video"
              :data-mychannels-id="currentChannel.myChannelsId"
              data-mychannels-options="FTTFU_default"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="fullscreen:hidden flex flex-col items-center mt-8">
      <PlayerControls :player="videoPlayer" />
    </div>
  </div>
</template>

<style>
div.mychannels > article,
.pe__container__player {
  width: 100%;
  height: 100%;
}

div.mychannels video {
  @apply rounded;
}

.pe__container__ui {
  display: none;
}
</style>
