<script setup>
import { computed } from 'vue'
import { useCurrentChannelStore } from '../../stores/currentChannel'
import { useNowPlayingStore } from '../../stores/nowPlaying'
import CircleButton from '../elements/CircleButton.vue'
import CoverImage from './CoverImage.vue'
import PreviousIcon from '../../assets/icons/previous.svg'

defineProps({
  coverClass: {
    type: Array,
    required: true,
  },
})

const currentChannel = useCurrentChannelStore()
const nowPlaying = useNowPlayingStore()

const tracksHistory = computed(() => nowPlaying.previousTracks(currentChannel.stationId, 1, 2))

const oldTrack = computed(() => tracksHistory.value[0])
const olderTrack = computed(() => tracksHistory.value[1])
</script>

<template>
  <div class="relative group -ml-8 pl-8">
    <div class="absolute z-0 left-0 group-hover:-left-10 top-0 scale-90 opacity-50 transition-all duration-500">
      <CoverImage v-if="olderTrack" :track="olderTrack" :class="coverClass" />
    </div>
    <div
      class="absolute z-10 left-4 group-hover:-left-6 top-0 scale-95 opacity-80 group-hover:opacity-100 transition-all duration-500"
    >
      <a
        :href="currentChannel.playlistUrl"
        target="_blank"
        class="group/overlay opacity-0 group-hover:opacity-100 absolute z-20 w-28 h-full px-3 flex flex-col items-center justify-center space-y-4 backdrop-blur-sm rounded-lg overflow-hidden transition-all duration-500 cursor-pointer willy:rounded-none"
      >
        <div class="text-white font-bold text-xl text-center leading-none">Check vorige tracks</div>
        <CircleButton class="group-hover/overlay:bg-primary">
          <PreviousIcon
            class="text-primary group-hover/overlay:text-primary-alt h-5 w-5 willy:text-primary-alt willy:group-hover/overlay:text-white"
          />
        </CircleButton>
      </a>
      <CoverImage
        v-if="oldTrack"
        :track="oldTrack"
        class="group-hover:brightness-50 transition-all duration-500"
        :class="coverClass"
      />
    </div>
    <div class="relative z-20 group-hover:ml-14 transition-all duration-500" :class="coverClass">
      <slot />
    </div>
  </div>
</template>
