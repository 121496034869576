class Logger {
  constructor(moduleName) {
    this.prefix = moduleName ? `[${moduleName}]` : ''
  }

  info(...args) {
    console.log(this.prefix, ...args)
  }

  error(...args) {
    console.error(this.prefix, ...args)
  }
}

export function setupLogger(moduleName) {
  return new Logger(moduleName)
}
