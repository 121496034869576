import { api, dataLayer } from '@dpgradio/creative'
import { defineStore } from 'pinia'
import { watch } from 'vue'
import { useRatingsStore } from './ratings'

export const useUserStore = defineStore('user', {
  state: () => ({
    radioToken: null,
    askingForLogin: false,
    ratings: useRatingsStore(),
  }),
  actions: {
    setToken(token) {
      this.radioToken = token

      api.setRadioToken(token)

      if (token) {
        this.askingForLogin = false
        this.sendAccountToDataLayer()

        this.ratings.initialize() // Call asynchronously
      }
    },
    askForLogin() {
      this.askingForLogin = true
    },
    sendAccountToDataLayer() {
      const user = JSON.parse(window.atob(this.radioToken?.split('.')[1]))
      dataLayer.pushEvent('user', { accountId: user.uid, logged_in: true })
    },
    requireAuthentication() {
      return new Promise((resolve, reject) => {
        if (this.radioToken) {
          return resolve()
        }
        this.askForLogin()
        watch(
          () => this.askingForLogin,
          () => (this.radioToken ? resolve() : reject('There is no authenticated user.'))
        )
      })
    },
  },
})
