import { privacy, loadScript, config } from '@dpgradio/creative'
import { setupLogger } from '../util/log'

const logger = setupLogger('CxenseParameters')

export default class CxenseParameters {
  constructor() {
    this.parameters = {}

    this.segments = undefined
  }

  async getParameters() {
    const persistedQueryId = config('app.cxense_persisted_query_id')

    if (persistedQueryId) {
      await this.addSegmentParameters(persistedQueryId)
    }

    return this.parameters
  }

  async addSegmentParameters(persistedQueryId) {
    if (this.segments === undefined) {
      try {
        const waitForConsent = privacy.waitForConsent()
        const loadSegments = this.getCxenseSegments(persistedQueryId)

        const consent = await waitForConsent

        this.segments = consent.allowsTargetedAdvertising() ? await loadSegments : null
      } catch (error) {
        logger.error('Segments could not be added:', error)
        this.segments = null
      }
    }
    if (this.segments) {
      this.parameters['aw_0_1st.cxsg'] = JSON.stringify(this.segments)
    }
  }

  getCxenseSegments(persistedQueryId, timeout = 1000) {
    return new Promise((resolve, reject) => {
      loadScript('https://cdn.cxense.com/cx.js', { timeout }).catch(reject)

      const cxenseCallTimeout = setTimeout(() => {
        reject('Loading Cxense segments timed out.')
      }, timeout)

      window.cX = window.cX || {}
      window.cX.callQueue = window.cX.callQueue || []

      window.cX.callQueue.push([
        'invoke',
        () => {
          clearTimeout(cxenseCallTimeout)
          const segments = window.cX.getUserSegmentIds({ persistedQueryId })
          const segmentsWithoutDuplicates = [...new Set(segments)]
          resolve(segmentsWithoutDuplicates)
        },
      ])
    })
  }
}
