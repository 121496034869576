<script setup>
import { useCurrentChannelStore } from '../stores/currentChannel'
import Slider from '@vueform/slider'
import { computed, ref, watch } from 'vue'
import checkIsMobile from '../util/isMobile'
import PlaylistIcon from '../assets/icons/playlist.svg'
import WatchIcon from '../assets/icons/watch.svg'
import MuteIcon from '../assets/icons/mute.svg'
import SpeakerIcon from '../assets/icons/speaker.svg'
import ListenIcon from '../assets/icons/listen.svg'
import StopIcon from '../assets/icons/stop.svg'
import PauseIcon from '../assets/icons/pause.svg'
import PlayIcon from '../assets/icons/play.svg'
import SpinIcon from '../assets/icons/spin.svg'

const props = defineProps({
  player: {
    type: Object,
    required: true,
  },
})

const currentChannel = useCurrentChannelStore()

const isMobile = checkIsMobile()

const playButton = computed(() => {
  if (props.player.isPlaying && props.player.stop) {
    return {
      action: props.player.stop,
      icon: StopIcon,
      description: 'Stoppen',
    }
  }
  if (props.player.isPlaying && props.player.pause) {
    return {
      action: props.player.pause,
      icon: PauseIcon,
      description: 'Pauzeren',
    }
  }
  if (props.player.isStopped || props.player.isPaused) {
    return {
      action: props.player.play,
      icon: PlayIcon,
      description: 'Afspelen',
    }
  }
  if (props.player.isLoading) {
    return { icon: SpinIcon, description: 'Laden' }
  }
  return {}
})

const sliderVolume = ref(props.player.volume)
let updateSliderVolume = true
watch(
  () => props.player.volume,
  () => {
    if (updateSliderVolume) {
      sliderVolume.value = props.player.volume
    }
  }
)
const startDragging = () => (updateSliderVolume = false)
const endDragging = () => {
  updateSliderVolume = true
  sliderVolume.value = props.player.volume
}
</script>

<template>
  <div class="w-full center space-y-4">
    <div v-if="!isMobile" class="w-full max-w-sm px-4 mx-auto md:px-0 flex items-center space-x-2">
      <button class="p-1 hover:opacity-80" :title="player.muted ? 'Ontdempen' : 'Dempen'" @click="player.toggleMute()">
        <MuteIcon
          class="w-5 h-5"
          :class="{
            'text-secondary': player.muted,
            'text-gray-800 fullscreen:text-white': !player.muted,
          }"
        />
      </button>
      <div class="flex-1 transition-all duration-300" :class="{ 'opacity-40': player.muted }">
        <Slider
          :min="0"
          :max="1"
          :step="0.01"
          :tooltips="false"
          :value="sliderVolume"
          :classes="{
            horizontal: 'slider-horizontal h-6',
            base: 'w-full h-full relative z-1 bg-gray-400 rounded',
            connect:
              'absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-secondary cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-gray-400 disabled:cursor-not-allowed',
            handle:
              'absolute rounded-full bg-primary border-4 border-white joe-be:border-primary joe:bg-secondary shadow cursor-grab focus:outline-none w-5 h-5 -top-2 -right-2 txt-rtl-h:-left-2 txt-rtl-h:right-auto disabled:cursor-not-allowed focus:ring focus:ring-secondary focus:ring-opacity-30',
          }"
          title="Volume aanpassen"
          @slide="player.setVolume($event)"
          @start="startDragging"
          @end="endDragging"
        />
      </div>
      <button
        class="p-1 pl-2 hover:opacity-80"
        :class="{ 'opacity-40': player.muted }"
        title="Maximaal volume"
        @click="player.setVolume(1)"
      >
        <SpeakerIcon class="w-5 h-5 text-gray-800 fullscreen:text-white" />
      </button>
    </div>

    <div class="flex items-center justify-center space-x-4">
      <div class="flex items-center w-24">
        <a
          :href="currentChannel.playlistUrl"
          target="_blank"
          class="ml-auto fullscreen:hidden group transition-all duration-300 flex flex-col items-center leading-none"
          title="Check vorige tracks"
        >
          <PlaylistIcon class="w-6 h-6 my-1 text-gray-700 group-hover:text-secondary transition-all duration-300" />
          <div class="uppercase text-xs text-gray-700">Vorige tracks</div>
        </a>
      </div>
      <button
        class="rounded-full bg-primary h-16 w-16 flex items-center justify-center shadow-xl text-primary-alt disabled:bg-opacity-70 hover:bg-opacity-80 border border-gray-100 transition-all duration-300 willy:w-12 willy:h-12 willy:rounded-none willy:border-none"
        :disabled="!playButton.action"
        :title="playButton.description || 'Onbekend'"
        @click="playButton.action"
      >
        <component :is="playButton.icon" v-if="playButton.icon" class="w-6 h-6" />
      </button>
      <div class="flex items-center w-24" :class="{ invisible: !currentChannel.supportsWatch }">
        <button
          v-if="currentChannel.mode === 'watch'"
          class="group transition-all duration-300 flex flex-col items-center leading-none"
          title="Schakel naar luisteren zonder beeld"
          @click="currentChannel.switchToListen"
        >
          <ListenIcon class="w-6 h-6 my-1 text-gray-700 group-hover:text-secondary transition-all duration-300" />
          <div class="uppercase text-xs text-gray-700">Luister live</div>
        </button>
        <button
          v-else
          class="group transition-all duration-300 flex flex-col items-center leading-none"
          title="Schakel naar luisteren met beeld"
          @click="currentChannel.switchToWatch"
        >
          <WatchIcon class="w-6 h-6 my-1 text-gray-700 group-hover:text-secondary transition-all duration-300" />
          <div class="uppercase text-xs text-gray-700">Kijk live</div>
        </button>
      </div>
    </div>
  </div>
</template>

<style>
@import '@vueform/slider/themes/tailwind.scss';
</style>
