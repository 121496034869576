import { api, onLocalStorageChange } from '@dpgradio/creative'
import { useUserStore } from './stores/user'

const RADIO_TOKEN_LOCAL_STORAGE_KEY = 'radio-auth-token'

export default function initializeRadioTokenExchange() {
  const user = useUserStore()

  onLocalStorageChange(RADIO_TOKEN_LOCAL_STORAGE_KEY, (token) => user.setToken(token), true)

  api.addErrorHandler(async ({ response }) => {
    if (response.status === 401) {
      console.error('401 Unauthorized. Token may be expired. Refreshing...')
      await refreshToken()
    }
  })

  const refreshToken = async () => {
    try {
      const { token: newToken } = await fetch('/login/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())

      if (!newToken) {
        throw Error('No token returned from refresh endpoint.')
      }

      user.setToken(newToken)
      localStorage.setItem(RADIO_TOKEN_LOCAL_STORAGE_KEY, newToken)
    } catch (e) {
      console.error('Token could not be refreshed.', e)
      user.setToken(null)
      localStorage.removeItem(RADIO_TOKEN_LOCAL_STORAGE_KEY)
    }
  }
}
