import { defineStore } from 'pinia'
import { api } from '@dpgradio/creative'
import { useNowPlayingStore } from './nowPlaying'

export const useChannelsStore = defineStore('channels', {
  state: () => ({
    channels: [],
  }),
  getters: {
    main() {
      return this.channels[0]
    },
  },
  actions: {
    async initialize() {
      const channels = await api.channels.all()

      this.channels = channels.map((channel) => ({ ...channel, station: channel.data }))

      this.channels.forEach((channel) => {
        useNowPlayingStore().initialize(channel.data.id)
      })
    },
  },
})
