<script setup>
import { computed } from 'vue'
import router from '../../router'
import { useChannelsStore } from '../../stores/channels'
import { useCurrentChannelStore } from '../../stores/currentChannel'
import ChannelOption from './ChannelOption.vue'

const channels = useChannelsStore()
const currentChannel = useCurrentChannelStore()

const nonActiveChannels = computed(() => channels.channels.filter((channel) => channel !== currentChannel.channel))

const switchChannel = (channel) => router.navigateTo(channel.station.id)
</script>

<template>
  <div class="space-x-3 md:space-x-0 md:space-y-3">
    <div v-for="channel in nonActiveChannels" :key="channel.id">
      <ChannelOption :channel="channel" class="cursor-pointer" @click="switchChannel(channel)" />
    </div>
  </div>
</template>
