<script setup>
import { computed } from 'vue'
import { cdnImageUrl } from '@dpgradio/creative'
import { useCurrentChannelStore } from '../../stores/currentChannel'
import { useNowPlayingStore } from '../../stores/nowPlaying'

const currentChannel = useCurrentChannelStore()
const nowPlaying = useNowPlayingStore()

const program = computed(() => nowPlaying.programs[currentChannel.stationId])

const imageType = 'program_square'
const image = computed(() =>
  cdnImageUrl(program.value.header_images.filter((image) => imageType in image)?.[0]?.[imageType])
)
</script>

<template>
  <section class="flex items-center space-x-3 px-6 pr-12 pb-2">
    <img
      v-if="image"
      class="h-14 aspect-square rounded-full shadow-xl object-cover willy:square-mask willy:rounded-none"
      :src="image"
    />
    <div>
      <h1
        class="text-2xl leading-none font-semibold line-clamp-1 pt-1 willy:uppercase willy:font-extra willy:tracking-[1px]"
      >
        {{ program.title }}
      </h1>
      <p class="text-gray-700 line-clamp-1">{{ program.human_schedule_copy }}</p>
    </div>
  </section>
</template>
