import { defineStore } from 'pinia'
import { socket } from '@dpgradio/creative'

export const useNowPlayingStore = defineStore('nowPlaying', {
  state: () => ({
    plays: {},
    programs: {},
  }),
  getters: {
    previousTracks() {
      return (stationId, skip = 0, take = Infinity) =>
        this.plays[stationId]
          ?.slice(0, -1)
          ?.reverse()
          ?.slice(skip, take + skip) || []
    },
    previousTrack() {
      return (stationId) => this.previousTracks(stationId, 0, 1)[0]
    },
    currentTrack() {
      return (stationId) => {
        if (!this.plays[stationId]?.length) {
          return null
        }
        return this.plays[stationId][this.plays[stationId].length - 1]
      }
    },
    nextTrack() {
      return (stationId) => {
        if (this.currentTrack(stationId) && 'next' in this.currentTrack(stationId)) {
          return this.currentTrack(stationId).next
        }
        return null
      }
    },
  },
  actions: {
    initialize(stationId) {
      this.plays[stationId] = []
      socket.join({ station: stationId, entity: 'plays', action: 'play', options: { backlog: 5 } }, (play) => {
        this.plays[stationId].push(play)
      })
      socket.join({ station: stationId, entity: 'program', action: 'change', options: { backlog: 1 } }, (program) => {
        this.programs[stationId] = program
      })
    },
  },
})
