<script setup>
import { computed } from 'vue'
import HistoryWrapper from './HistoryWrapper.vue'
import CoverImage from './CoverImage.vue'
import LikeButton from './LikeButton.vue'
import VinylImage from '../../assets/images/vinyl.png'
import PositionLabel from '../elements/PositionLabel.vue'
import { config } from '@dpgradio/creative'

const props = defineProps({
  track: {
    type: Object,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  large: {
    type: Boolean,
    default: false,
  },
  showHistory: {
    type: Boolean,
    default: false,
  },
})

const showLikeButton = computed(() => props.track && config('app.support_favorite_song') !== 'false')
const coverImageDimensions = computed(() => [
  'aspect-square',
  props.large
    ? 'max-w-[14rem] md:max-w-[20rem] xl:max-w-sm h-40 h-md:h-64 h-lg:h-auto joe-be:md:!max-w-xs joe:md:!max-w-xs'
    : 'max-w-[12rem] h-40 h-md:h-48',
])
</script>

<template>
  <div
    class="flex-col items-center space-y-4 transition-all"
    :class="{ invisible: !track, 'w-64 hidden lg:flex': !large, flex: large }"
  >
    <span v-if="label" class="text-gray-700 mb-1">{{ label }}</span>
    <div class="relative">
      <PositionLabel :track="track" class="absolute left-0 right-0 mx-auto -top-4" />
      <div v-if="showHistory">
        <HistoryWrapper :coverClass="coverImageDimensions">
          <CoverImage :track="track" />
        </HistoryWrapper>
        <LikeButton v-if="showLikeButton" :track="track" class="absolute z-20 left-0 right-0 mx-auto -bottom-5" />
      </div>
      <div v-else class="group" :class="coverImageDimensions">
        <img
          v-if="props.large"
          :src="VinylImage"
          class="qmusic:hidden absolute group-hover:xl:left-20 group-hover:animate-spin duration-300 group-hover:transition-all xl:left-16 z-1 w-full h-full rounded-full block"
        />
        <CoverImage :track="track" class="z-10 relative" />
        <LikeButton v-if="showLikeButton" :track="track" class="absolute z-20 left-0 right-0 mx-auto -bottom-5" />
      </div>
    </div>
    <div class="pt-6 text-center">
      <div class="font-semibold" :class="large ? 'text-3xl' : 'text-2xl'">
        {{ track?.title }}
      </div>
      <div :class="large ? 'text-xl' : 'text-lg'">
        {{ track?.artist?.name }}
      </div>
    </div>
  </div>
</template>
