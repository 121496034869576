import { setupLogger } from './util/log'

const logger = setupLogger('router')

class Router {
  constructor(basePath = import.meta.env.BASE_URL) {
    this.basePath = basePath.replace(/^\/|\/$/g, '')
    this.routes = []
    this.booted = false
    this.queuedRoute = null

    window.addEventListener('popstate', () => this.run())
  }

  async navigateTo(...path) {
    const url = new URL(window.location)
    url.pathname = [this.basePath, ...path].join('/')
    window.history.pushState({}, '', url)
    await this.run(url)
  }

  register(regex, callback) {
    this.routes.push({ regex, callback })
  }

  async start() {
    this.booted = true
    if (this.queuedRoute) {
      await this.run(this.queuedRoute)
      this.queuedRoute = null
    } else {
      await this.run()
    }
  }

  async run(url = new URL(window.location)) {
    if (!this.booted) {
      logger.info('Router not booted yet, queuing url: ' + url)
      this.queuedRoute = url
      return
    }

    const path = url.pathname.replace(new RegExp(`^/?${this.basePath}/?`), '').replace('index.html', '')
    const route = this.routes.find(({ regex }) => regex.test(path))

    if (route) {
      await route.callback(...path.match(route.regex).slice(1))
    } else {
      throw new Error('No route found for path: ' + path + ', current number of routes: ' + this.routes.length)
    }
  }
}

export default new Router()
